import { h } from 'preact';

import { useEffect, useState } from "preact/hooks";

import { Back } from "../icons/back"
import { Comic } from "../icons/comic"
import { Folder } from "../icons/folder"

const List = (params) => {
  const { back, list, index, onClick } = params;

  useEffect(() => {
    //window.scrollX = 0;
  }, []);

  return (
    <div class="list">
      {list && index != 0 && <div class="item" onClick={back}><Back /></div>}
      {list && list.map(item => <div class="item" onClick={() => onClick(item)}>{item.type === "dir" ? <Folder /> : <Comic />} <div>{item.name}</div></div>)}
    </div>
  );
};

export { List }