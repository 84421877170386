import { h } from "preact";
import { useEffect, useState } from "preact/hooks";

import { CSSTransition, TransitionGroup } from 'preact-transitioning'

import { List } from "./list"
import { View } from "./view"

let page = 0;
let items = 0;
let startX = false;
let previousX;
let timestamp;
let startTimestamp;

const App = () => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState(false);
  const [path, setPath] = useState(false);
  const [file, setFile] = useState(false);

  const [lists, setLists] = useState([]);

  useEffect(async () => {
    let response;

    if (process.env.NODE_ENV==='development') {
      response = await fetch("https://comics.gigant.us/list.php");
    } else {
      response = await fetch("https://comics.gigant.us/list.php");
    }

    const list = await response.json();

    console.log(list);
    setList(list);
    lists.push(list);
    setLists(lists);
  }, []);

  useEffect(async () => {
    if (path !== false) {
      console.log("___________ path changed", path);
      const response = await fetch("https://comics.gigant.us/list.php?" + new URLSearchParams({dir: path}));
      const list = await response.json();

      setList(list);
      lists.push(list);
      setLists(lists);
      console.log("useEffect path", lists);
    }
  }, [path]);

  useEffect(async () => {
      console.log("___________ lists changed", lists);
  }, [lists]);

  async function action(item) {
    console.log(item.type, item.file);
    switch (item.type) {
      case "dir": {
        console.log("is dir");

        setPath(item.file);
      }
      break;

      case "file": {
        console.log("is file");

        //const response = await fetch(`https://comics.gigant.us/read.php?file=${encodeURI(item.file)}`);
        const response = await fetch("https://comics.gigant.us/read.php?" + new URLSearchParams({file: item.file}));
        const file = await response.json();

        console.log(file);
        setFile(file);

        items = file.fileList.length;
      }
      break;
    }
  }

  function back() {
    setLists([
      ...lists.slice(0, -1)
    ]);
    setPath(false);
/*
    const pathArray = path.split("/");
    if (pathArray.length !== 0) {
      pathArray.pop();
      setPath(pathArray.join("/"));
    }
*/
  }

  function close() {
    setFile(false);
  }

  return (
    <div id="app">
      {/*loading && <div class="loading"></div>*/}
      <TransitionGroup duration={450}>
        {lists.map((item, index) => {
          if (index === 0) {
            return (
              <div className="listContainer">
                <List list={item} index={index} onClick={action} back={back} />
              </div>
            );
          }

          return (
            <CSSTransition classNames="slide" key={item}>
              <div className="listContainer">
                <List list={item} index={index} onClick={action} back={back} />
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>

      {/*<div id="list">
        {list && path != "" && <div class="item" onClick={back}>..</div>}
        {list && list.map(item => <div class="item" onClick={() => action(item)}>{item.name}</div>)}
      </div>*/}

      <View file={file} close={close} />

{/*
      {file && (
        <div id="view">
          <div id="viewList" style={{transform: `translateX(-${translation}px)`}} onMouseDown={(e) => handleMove(e)} onMouseUp={(e) => handleMove(e)} onMouseMove={(e) => handleMove(e)} onTouchStart={(e) => handleMove(e)} onTouchEnd={(e) => handleMove(e)} onTouchMove={(e) => handleMove(e)}>
            {file.fileList.map(item => (
              <div key={item.index}>
                <div className="image" style={{backgroundImage: `url("https://comics.gigant.us/read.php?file=${file.file}&page=${item.url}")`}}></div>
              </div>
            ))}
          </div>
        </div>
      )}
*/}
    </div>
  );
};

export default App;
