import { h } from 'preact';

const Folder = () => (
  <svg style={{marginRight: "5px"}} width="22px" height="22px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path fill="none" d="M0 0h24v24H0z"/>
      <path fill="#ffffff" d="M13.414 5H20a1 1 0 0 1 1 1v1H3V4a1 1 0 0 1 1-1h7.414l2 2zM3.087 9h17.826a1 1 0 0 1 .997 1.083l-.834 10a1 1 0 0 1-.996.917H3.92a1 1 0 0 1-.996-.917l-.834-10A1 1 0 0 1 3.087 9z"/>
    </g>
  </svg>
);

export { Folder }