import { h } from 'preact';

const Back = () => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style={{margin:"3px"}} width="18px" height="18px" viewBox="0 0 534.000000 534.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,534.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
      <path d="M1900 4934 c-86 -18 -212 -82 -277 -139 -49 -43 -1401 -1626 -1490 -1745 -83 -110 -133 -255 -133 -385 0 -128 55 -288 131 -382 181 -221 1452 -1703 1492 -1738 142 -125 353 -180 542 -140 333 70 551 405 480 738 -33 155 -70 210 -417 617 l-235 275 1421 5 c1347 5 1424 6 1476 23 320 108 502 413 435 732 -38 180 -163 344 -326 424 -151 75 -62 71 -1621 71 l-1398 0 258 303 c293 345 320 380 362 469 82 175 78 366 -11 547 -123 249 -411 385 -689 325z"/>
    </g>
  </svg> 
);

export { Back }