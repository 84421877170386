import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

/*
const NF = 30,
      w = window.innerWidth,
			TFN = {
				'linear': function(k) { return k }, 
				'ease-in': function(k, e = 1.675) {
					return Math.pow(k, e)
				}, 
				'ease-out': function(k, e = 1.675) {
					return 1 - Math.pow(1 - k, e)
				}, 
				'ease-in-out': function(k) {
					return .5*(Math.sin((k - .5)*Math.PI) + 1)
				}
			};

let N, i = 0, x0 = null, locked = false, ini, fin, rID = null, anf;

function stopAni() {
  cancelAnimationFrame(rID);
  rID = null
};

function ani(cf = 0) {
  console.log("____ani", ini, fin);
  document.body.style.setProperty('--i', ini + (fin - ini)*TFN['ease-out'](cf/anf));
	
  if (cf === anf) {
    stopAni();
    return
  }
	
  rID = requestAnimationFrame(ani.bind(this, ++cf))
};

function unify(e) {	return e.changedTouches ? e.changedTouches[0] : e };

function lock(e) {
  x0 = unify(e).clientX;
	locked = true
};

function drag(e) {
  e.preventDefault();
	
  if (locked) {
    console.log("____drag");
    let dx = unify(e).clientX - x0, f = +(dx/w).toFixed(2);
		
    document.body.style.setProperty('--i', i - f)
  }
};

function move(e) {
  if (locked) {
    console.log("____move");
    let dx = unify(e).clientX - x0, 
        s = Math.sign(dx), 
        f = +(s*dx/w).toFixed(2);
		
    ini = i - s*f;

    if((i > 0 || s < 0) && (i < N - 1 || s > 0) && f > .2) {
      i -= s;
      f = 1 - f
    }

    fin = i;
		anf = Math.round(f*NF);
    if (ini != fin) {
      ani();
    }
    x0 = null;
    locked = false;
  }
};
*/


const NF = 30,
w = window.innerWidth,
TFN = {
  'linear': function(k) { return k }, 
  'ease-in': function(k, e = 1.675) {
    return Math.pow(k, e)
  }, 
  'ease-out': function(k, e = 1.675) {
    return 1 - Math.pow(1 - k, e)
  }, 
  'ease-in-out': function(k) {
    return .5*(Math.sin((k - .5)*Math.PI) + 1)
  }
};

let N, i = 0, x0 = null, locked = false, ini, fin, rId = null, anf;

const View = (props) => {
  const { file, close } = props;

  const [page, setPage] = useState();
  const [pages, setPages] = useState();
  const [files, setFiles] = useState([]);

  let doubleclickTimestamp;

  useEffect(() => {
    setPage(0);

    i = 0;
    x0 = null;
    locked = false;
    rId = null;
    doubleclickTimestamp = undefined; 
    console.log("reset");
  }, []);

  useEffect(() => {
    if (file && file.fileList) {
      console.log("file changed", file);
      document.body.style.setProperty("--n", file.fileList.length);
      document.body.style.setProperty('--i', 0);

      N = file.fileList.length;

      if (file.fileList.length > 3) {
        setFiles(file.fileList.map((item, index) => {
          return (index < 3) ? item : { index: item.page, url: "" };
        }));
      } else {
        setFiles(file.fileList);
      }

      setPage(1);
      setPages(file.fileList.length);
    } else {
      setPage(0);
      setFiles([]);

      i = 0;
      x0 = null;
      locked = false;
      rId = null;
      console.log("reset");
    }
  }, [file]);

  useEffect(() => {
    if (file) {
      setFiles(file.fileList.map((item, index) => {
        return (index < page + 3) ? item : { index: item.page, url: "" };
      }));
    }
  }, [page]);

  function stopAni() {
    cancelAnimationFrame(rId);
    rId = null
  };

  function ani(cf = 0) {
    //console.log("____ani", ini, fin);
    document.body.style.setProperty('--i', ini + (fin - ini)*TFN['ease-out'](cf / anf));
    
    if (cf === anf) {
      setPage(fin + 1);
      stopAni();
      return
    }
    
    rId = requestAnimationFrame(ani.bind(this, ++cf))
  };

  function unify(e) {	return e.changedTouches ? e.changedTouches[0] : e };

  function lock(e) {
    x0 = unify(e).clientX;
    locked = true
  };

  function drag(e) {
    e.preventDefault();
    
    if (locked) {
      //console.log("____drag");
      let dx = unify(e).clientX - x0, f = +(dx / w).toFixed(2);
      
      document.body.style.setProperty('--i', i - f)
    }
  };

  function move(e) {
    if (locked) {
      console.log("____move");
      let dx = unify(e).clientX - x0, 
          s = Math.sign(dx), 
          f = +(s * dx / w).toFixed(2);
      
      ini = i - s*f;

      if ((i > 0 || s < 0) && (i < N - 1 || s > 0) && f > .2) {
        i -= s;
        f = 1 - f
      }

      fin = i;
      anf = Math.round(f*NF);
      if (ini != fin) {
        ani();
      }
      x0 = null;
      locked = false;

      const lastTouch = doubleclickTimestamp || Date.now() + 350 /** the first time this will make delta a negative number */;
      const delta = Date.now() - lastTouch;
      if (delta < 350 && delta > 0) {
        console.log("double tap", delta);
        e.preventDefault();
        close();
      }

      doubleclickTimestamp = Date.now();
    }
  };

  if (file) {
    console.log("render");
    return (
      <div id="view" onMouseDown={(e) => lock(e)} onMouseUp={(e) => move(e)} onMouseMove={(e) => drag(e)} onTouchStart={(e) => lock(e)} onTouchEnd={(e) => move(e)} onTouchMove={(e) => drag(e)} onDblClick={close}>
        <div id="viewList">
          {files.map(item => (
            <div class="viewListItem">
              <div class="loading"></div>
              <div class="image" style={{backgroundImage: `url(https://comics.gigant.us/${item.url})`}}></div>
              {/*<img class="image" src={`https://comics.gigant.us/${item.url}`} />*/}
            </div>
          ))}
        </div>

        <div id="counter">{page} / {pages}</div>
      </div>
    );
  } else {
    console.log("no render");
  }

  return null;
};

export { View };